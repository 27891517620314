<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-col>
      <span
        style="
          font-family: 'IBM Plex Sans Thai';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        "
      >
        แก้ไขวิทยานิพนธ์ และ งานวิจัย
      </span>
    </v-col>
    <v-card class="pa-6" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อ (TH)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="name_th"
              placeholder="ชื่อ (TH)"
              dense
              outlined
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อ (EN)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="name_en"
              placeholder="ชื่อ (EN)"
              dense
              outlined
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">Upload File</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="9" sm="9">
            <input
              type="file"
              accept="application/pdf,"
              id="file"
              ref="download_url"
              v-on:change="submitFile('download_url')"
              disabled
            />
          </v-col>
          <v-col cols="12" md="3" sm="3" v-if="download_url"></v-col>
          <v-col cols="12" md="9" sm="9" class="text-left" v-if="download_url">
            <p
              style="color: #4caf50; cursor: pointer"
              @click="onClickFile(download_url)"
            >
              ดูไฟล์
            </p>
          </v-col>
        </v-row>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <!-- <v-btn @click="submit('q_1_1')" dark color="#2AB3A3">บันทึก</v-btn> -->
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      name_en: "",
      name_th: "",
      download_url: "",

      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "CONTENT",
      category: "",
      desc: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      img: "",
      loading: false,
      content_url: "",
      file: [],
      video_url: "",
      itemTypes: [
        {
          name: "CONTENT",
          val: "CONTENT",
        },
        {
          name: "VIDEO",
          val: "VIDEO",
        },
      ],
      itemCategory: [
        {
          name: "แนะนำ",
          val: "SUGGEST",
        },
        {
          name: "ครอบครัว",
          val: "HOME2",
        },
        {
          name: "งานวิจัย",
          val: "RESEARCH",
        },
      ],
      levelItems: [],
      FacultyItems: [],
      dataThesisAndResearch: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.dataThesisAndResearch = JSON.parse(
      Decode.decode(localStorage.getItem("dataThesisAndResearch"))
    );
    this.getOne();
  },
  methods: {
    async getOne() {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      console.log("this.userData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/thesisAndResearchs/${this.dataThesisAndResearch.id}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbannerpe", response.data.data);
      // this.name_th = response.data.data.name_th;
      this.name_th = response.data.data.name_th;
      this.name_en = response.data.data.name_en;
      this.download_url = response.data.data.download_url;
      this.loading = false;
    },

    cancel() {
      this.$router.push("ManageThesisAndResearch");
    },

    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // if (this.type == "CONTENT") {
        //   await this.submitfileMain(pic);
        //   console.log("pic", pic);
        // }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          name_en: this.name_en,
          name_th: this.name_th,
          download_url: this.download_url,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/thesisAndResearchs/${this.dataThesisAndResearch.id}`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขวิทยานิพนธ์ และ งานวิจัย`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageThesisAndResearch");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        this.loading = true;
        if (this.$refs[questionNo].files[0]) {
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `https://gscm-service.yuzudigital.com/files/formData`,
            formData,
            auth
          );
          if (questionNo == "download_url") {
            this.download_url = response.data.data.path;
          }
          console.log("SUCCESS!!");
          console.log("response", response);
        }
        this.loading = false;
      }
    },
    onClickFile(val) {
      window.open(val, "_blank");
    },
  },
};
</script>
